.beneficios-container {
    background: linear-gradient(to bottom, #05445E, #189AB4);
    padding: 60px 20px;
    text-align: center;
    color: white;
    position: relative;
  }
  
  .beneficios-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .beneficios-content h3 {
    font-size: 23px;
    margin-bottom: 20px;
  }
   
.colonnus2 {
    color: white;
    font-size: 32px;
  }
  
  .beneficios-content p {
    font-size: 22px;
    margin-bottom: 20px;
  }
  
  .resaltado2 {
    color: white;
    font-weight: bold;
  }
  .Beneficion_cont{
    max-width: 710px;
    margin: 50px auto;
  }
  .beneficios-icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  
  .beneficio-item {
    flex: 1 1 200px;
    margin: 20px;
    text-align: center;
  }
  
  .beneficio-item img {
    max-width: 100px;
    margin-bottom: 10px;
  }
  
  .beneficio-item p {
    font-size: 1em;
    max-width: 200px;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  .Curves{
    position: absolute;
    bottom: -1%;
    left: -1%;
    width: 101vw;
  }