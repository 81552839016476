.secciones-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.seccion {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 450px; 
}

.filtro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.seccion-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 2;
}

.seccion-title {
  background-color: #FFC200;
  color: black;
  font-size: 42px;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 10px;
  max-width: 90%;
}

.seccion-description {
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  padding: 5px 10px;
  display: inline-block;
  max-width: 100%; 
}

.text_image {
  background-color: black;
  height: 100px;
  color: white;
  font-size: 36px;
  padding-bottom: 100px;
  font-weight: bold;
  text-align: center;
}

.position_image {
  position: absolute;
  bottom: 199px;
  width: 100%;
  z-index: 1;
}

/* Media Queries */
@media (max-width: 1200px) {
  .seccion {
    height: 400px;
  }
  .seccion-title {
    font-size: 36px;
  }
  .seccion-description {
    font-size: 20px;
  }
  .text_image {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .seccion {
    height: 350px;
  }
  .seccion-title {
    font-size: 32px;
  }
  .seccion-description {
    font-size: 18px;
  }
  .text_image {
    font-size: 28px;
    height: 50px;
  }
  .position_image {
    bottom: 150px;
  }
}

@media (max-width: 480px) {
  .seccion {
    height: 300px;
  }
  .seccion-title {
    font-size: 28px;
  }
  .seccion-description {
    font-size: 16px;
  }
  .text_image {
    font-size: 24px;
  }
}
