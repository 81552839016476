.opciones-container {
    position: relative;
    background-color: black;
    color: white;
    text-align: center;
    padding: 100px 20px;
  }
  
  .opciones-curve {
    position: absolute;
    top: -0.3%;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  
  .opciones-content {
    position: relative;
    z-index: 2;
    font-size: 36px;
  }
  
  .opciones-highlight {
    color: #FFC200;
  }
  
  .opciones-colonnus {
    font-weight: bold;
    margin-left: 30px;
    font-size: 42px;
  }
  