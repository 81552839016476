.vip-container {
  position: relative;
  background: linear-gradient(to bottom, rgba(24, 154, 180, 0.9) 70%, #05445E), url('../../public/Back_colo.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  padding: 60px 20px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.top-texture {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  left: 0;
  z-index: 1;
}

.vip-content {
  position: relative;
  z-index: 2;
}

.vip-content p {
  font-size: 32px;
}

.colonnus {
  color: #FFC200;
}

.vip-button {
  background-color: #FFC200;
  color: black;
  border: none;
  border-radius: 30px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  width: 40%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.vip-button:hover {
  background-color: #e6b800;
}

/* Media Queries */
@media (max-width: 1200px) {
  .vip-content p {
    font-size: 28px;
  }

  .vip-button {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .vip-content p {
    font-size: 24px;
  }

  .vip-button {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .vip-content p {
    font-size: 20px;
  }

  .vip-button {
    width: 80%;
  }
}
