.promocion {
  margin: 0 auto;
  position: relative;
  min-height: 1200px;
}

.Bakcgroung {
  background-image: 
    linear-gradient(to bottom, rgba(24, 154, 180, 0.9), #05445E),
    url('../../public/miami-living.png');
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  color: #333;
  position: absolute;
  width: 100%;
  min-height: 458px;
  top: 0;
  z-index: -1;
  border-bottom-left-radius: 1000px 100px;
  border-bottom-right-radius: 1000px 100px;
}

.promotion_title {
  background-color: #0A505E;
  margin: 0;
  padding: 1px;
}

.promocion_text {
  color: white;
  font-size: 22px;
  margin-top: 60px;
  line-height: 5px;
}

.promotion_title h3 {
  font-size: 23px;
  color: white;
  font-weight: bold;
}

.descuentos {
  color: #FFC200;
  font-size: 42px;
  font-weight: bold;
}

.promociones_color {
  color: #FFC200;
  font-size: 32px;
  line-height: 15px;
}

.Color_question {
  color: #189AB4;
}

.Text_decoration {
  color: white;
}

.Question {
  font-size: 20px;
  max-width: 880px;
  margin: 0 auto;
  text-align: center;
}

.Question button {
  background-color: #FFC200;
  border: none;
  border-radius: 30px;
  width: 100%;
  padding: 20px;
  font-weight: bold;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
}

.Img_video {
  max-width: 100%;
  box-shadow: 20px 5px 15px rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  cursor: pointer;
}

.Play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.Video_promocion {
  max-width: 100%;
  box-shadow: 20px 5px 15px rgba(0, 0, 0, 0.5);
  margin-top: 30px;
}

.Top_1 {
  position: absolute;
  bottom: -0.1%;
  left: 0;
  width: 100%;
  z-index: -2;
}

/* Media Queries */
@media (max-width: 1200px) {
  .promocion_text {
    font-size: 20px;
    line-height: 1.5;
  }

  .descuentos {
    font-size: 36px;
  }

  .promociones_color {
    font-size: 28px;
    line-height: 1.5;
  }

  .Question {
    font-size: 18px;
  }

  .Question button {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .promocion_text {
    font-size: 18px;
    line-height: 1.5;
  }

  .descuentos {
    font-size: 32px;
  }

  .promociones_color {
    font-size: 24px;
    line-height: 1.5;
  }

  .Question {
    font-size: 16px;
  }

  .Question button {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .promocion_text {
    font-size: 16px;
    line-height: 1.5;
  }

  .descuentos {
    font-size: 28px;
  }

  .promociones_color {
    font-size: 20px;
    line-height: 1.5;
  }

  .Question {
    font-size: 14px;
  }

  .Question button {
    padding: 8px;
  }
}
