.conocenos-wrapper {
  position: relative;
}

.conocenos-container {
  text-align: center;
  align-items: center;
  color: white;
  background: linear-gradient(to bottom, #189AB4, #05445E);
  padding: 40px 20px;
  position: relative;
  z-index: 1;
}

.colonnus {
  font-size: 28px;
  font-weight: bold;
  color: #05445E;
  margin: 0 10px;
}

.conocenos-content {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
}

.conocenos-item {
  max-width: 299px;
  margin-top: 40px;
  text-align: center;
  flex: 1 1 100%;
}

.conocenos-item img {
  width: 100%;
  height: 48px;
}

.conocenos-item p {
  font-size: 18px;
  line-height: 1.5;
}

.Bold_Text {
  font-size: 20px;
}

strong {
  font-size: 20px;
}

.aventureros {
  margin: 0 10px;
}


@media (min-width: 768px) {
  .conocenos-item {
    flex: 1 1 45%;
    margin: 20px;
  }
}

@media (min-width: 1200px) {
  .conocenos-item {
    flex: 1 1 30%;
    margin: 20px;
  }
}
