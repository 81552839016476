.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    background-color: #1c1c1c;
    color: white;
  }
  
  .footer-left p {
    margin: 0;
    color: #898989;
  }
  
  .footer-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-logo {
    height: 78px;
    width: 253px; 
  }
  
  .footer-right {
    display: flex;
    gap: 10px; 
  }
  
  .circle {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-left, .footer-right {
      margin: 10px 0;
    }
  }
  