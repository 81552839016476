.tarjeta-broker-container {
    text-align: center;
    padding: 20px;
  }
  
  .tarjeta-broker-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  
  .tarjeta-broker-subtitle {
    font-size: 32px;
    font-weight: bold;
    max-width: 1100px;
    margin: 0 auto;
  }
  