.informacion-container {
  position: relative;
  background-image: url('../../public/Back_2.png'); 
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 840px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.informacion-content {
  background-color: rgba(0, 0, 0, 0.5); 
  color: white;
  padding: 20px;
  border-radius: 40px;
  text-align: center;
  max-width: 900px;
  padding: 40px 100px;
  width: 90%;
  position: relative;
  z-index: 1;
}

.informacion-content h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.informacion-content ul {
  list-style: none;
  padding: 0;
}

.informacion-content li {
  max-width: 85%;
  margin: 0 auto;
  font-size: 23px;
  margin-bottom: 40px;
}

.resaltado {
  color: #FFC200; 
  font-weight: bold;
}

.link {
  color: #189AB4; 
  cursor: pointer;
}

.raya {
  width: 50%;
  height: 2px;
  margin: 50px auto;
  background-color: #FFC200;
}

/* Media Queries */
@media (max-width: 1200px) {
  .informacion-content {
    padding: 20px 50px;
  }
  
  .informacion-content h1 {
    font-size: 26px;
  }
  
  .informacion-content li {
    font-size: 20px;
    margin-bottom: 30px;
  }
  
  .raya {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .informacion-content {
    padding: 15px 30px;
  }
  
  .informacion-content h1 {
    font-size: 22px;
  }
  
  .informacion-content li {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .raya {
    width: 70%;
    margin: 30px auto;
  }
  
}

@media (max-width: 480px) {
  .informacion-content {
    padding: 10px 20px;
  }
  
  .informacion-content h1 {
    font-size: 18px;
  }
  
  .informacion-content li {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .raya {
    width: 80%;
    margin: 10px auto;
  }
}
