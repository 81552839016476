.ventajas-container {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff;
  color: #05445E;
}

.ventajas-colonnus {
  color: #189AB4;
  font-size: 42px;
  font-weight: bold;
}

.ventajas-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  padding: 100;
}

.ventajas-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ventajas-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.ventajas-resaltado {
  font-weight: bold;
}

.ventajas-item p {
  font-size: 1.2em;
  line-height: 1.5;
  max-width: 850px;
  text-align: center;
}

.ventajas-button {
  background-color: #FFC200;
  color: #05445E;
  border: none;
  border-radius: 30px;
  padding: 20px 30px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
  width: 40%;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.ventajas-button:hover {
  background-color: #e6b800;
}

.ventajas-container h2 {
  font-size: 32px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .ventajas-container h2 {
    font-size: 28px;
  }

  .ventajas-colonnus {
    font-size: 36px;
  }

  .ventajas-item p {
    font-size: 1.1em;
  }

  .ventajas-button {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .ventajas-container h2 {
    font-size: 24px;
  }

  .ventajas-colonnus {
    font-size: 32px;
  }

  .ventajas-item p {
    font-size: 1em;
  }

  .ventajas-button {
    width: 60%;
  }
}

@media (max-width: 480px) {
  .ventajas-container h2 {
    font-size: 20px;
  }

  .ventajas-colonnus {
    font-size: 28px;
  }

  .ventajas-item p {
    font-size: 0.9em;
  }

  .ventajas-button {
    width: 80%;
  }
}
